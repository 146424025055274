// // src/Banner.js
// import React, { useState } from 'react';
// import './Banner.css'; // Import the CSS for styling

// const Banner = ({isVisible, setIsVisible}) => {

//   const handleClose = () => {
//     setIsVisible(false);
//   };

//   if (!isVisible) return null;

//   return (
//     <div className="banner">
//       <div className="banner-content">
//         <a
//           href="https://time.com/7094922/ai-seer-facticity-ai/"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <img
//             src="/timesBest.png"
//             alt="Times Best Invention"
//             className="banner-image" // Use the CSS class instead of inline styles
//           />
//         </a>
//         <div className="banner-text">
//           <a
//             href="https://time.com/7094922/ai-seer-facticity-ai/"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <p>
//                 Recognized by TIME Best Inventions as one of 14 AIs for 2024
//             </p>
//           </a>
//         </div>
//         <button className="banner-close-button" onClick={handleClose}>
//           &times;
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Banner;


// src/Banner.js
import React from 'react';
import './Banner.css'; // Import the CSS for styling

const Banner = ({ isVisible, setIsVisible }) => {
  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="banner">
      <div className="banner-content">
        <a
          href="https://time.com/7094922/ai-seer-facticity-ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/timesBest.png"
            alt="Times Best Invention"
            className="banner-image"
          />
        </a>
        <div className="banner-text">
          <a
            href="https://time.com/7094922/ai-seer-facticity-ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>
              Recognized by TIME's Best Inventions of 2024 as one of 14 AIs
            </p>
          </a>
        </div>
        <button className="banner-close-button" onClick={handleClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default Banner;
