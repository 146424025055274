// import React, { useState } from 'react';
// import './Subscription.css';

// const Subscription = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const freeTier = {
//     title: "Free Tier",
//     price: "Free",
//     features: [
//       { text: "3 pro searches per day", available: true },
//       { text: "Limited fact-checking sources", available: true },
//       { text: "20 minutes of Video fact-checks per day", available: true },
//     ],
//   };

//   const proTier = {
//     title: "Pro Tier",
//     price: "$9.99/month",
//     features: [
//       { text: "Unlimited pro searches", available: true },
//       { text: "Access to detailed fact-checking sources", available: true },
//       { text: "3 hours of Video fact-checks per day", available: true },
//     ],
//   };

//   const renderFeatures = (features) => (
//     <ul className="feature-list">
//       {features.map((feature, index) => (
//         <li key={index} className="feature-item">
//           <span className={`feature-icon ${feature.available ? 'available' : 'unavailable'}`}>
//             {feature.available ? '✓' : '✗'}
//           </span>
//           {feature.text}
//         </li>
//       ))}
//     </ul>
//   );

//   const handleSubscribeClick = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="subscription-container">
//       <h1 className="page-title">Choose Your Facticity.AI Plan</h1>

//       <div className="plans-wrapper">
//         {/* Free Tier */}
//         <div className="plan-card free-tier">
//           <h2 className="plan-title">{freeTier.title}</h2>
//           <p className="plan-price">{freeTier.price}</p>
//           {renderFeatures(freeTier.features)}
//           <button className="plan-button free-button">
//             Current Plan
//           </button>
//         </div>

//         {/* Pro Tier */}
//         <div className="plan-card pro-tier">
//           <h2 className="plan-title">{proTier.title}</h2>
//           <p className="plan-price">{proTier.price}</p>
//           {renderFeatures(proTier.features)}
//           <button
//             className="plan-button pro-button"
//             onClick={handleSubscribeClick}
//           >
//             Coming Soon
//           </button>
//         </div>
//       </div>

//       {/* Modal */}
//       {isModalOpen && (
//         <div className="modal-overlay">
//           <div className="modal-content">
//             <h3 className="modal-title">Coming Soon</h3>
//             <p className="modal-text">
//               Our payment gateway is currently being integrated and will be available soon.
//             </p>
//             <p className="modal-subtext">
//               Stay tuned! In the meantime, enjoy your Pro Subscription Free Trial.
//             </p>
//             <button
//               className="modal-close-button"
//               onClick={handleCloseModal}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Subscription;

import React, { useState } from 'react';
import './Subscription.css';
import { useNavigate } from 'react-router-dom';
// Material-UI Components and Hooks
import { IconButton, Grid, Box, useTheme, useMediaQuery, Button, } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
// Material-UI Icons
import MenuIcon from '@mui/icons-material/Menu';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

// Authentication and Context
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from './AppProvider';

// Custom Components
import Sidebar from './components/Sidebar';
import ThirdColumn from './components/ThirdColumn';

const Subscription = () => {
  // Authentication
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  // App Context (Assuming you have an AppProvider that provides these)
  const {
    isSidebarOpen,
    toggleSidebar,
    handleCloseSidebar,
    setIsSearchMoved,
    createConversation,
  } = useAppContext();

  // Responsive Design
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define Subscription Tiers
  const freeTier = {
    title: "Free Tier",
    price: "Free",
    features: [
      { text: "3 pro searches per day", available: true },
      { text: "Limited fact-checking sources", available: true },
      { text: "20 minutes of Video fact-checks per day", available: true },
    ],
  };

  const proTier = {
    title: "Pro Tier",
    price: "$9.99/month",
    features: [
      { text: "Unlimited pro searches", available: true },
      { text: "Access to detailed fact-checking sources", available: true },
      { text: "3 hours of Video fact-checks per day", available: true },
    ],
  };

  // Helper to Render Features
  const renderFeatures = (features) => (
    <ul className="feature-list">
      {features.map((feature, index) => (
        <li key={index} className="feature-item">
          <span
            className={`feature-icon ${
              feature.available ? 'available' : 'unavailable'
            }`}
          >
            {feature.available ? '✓' : '✗'}
          </span>
          {feature.text}
        </li>
      ))}
    </ul>
  );

  // Handlers for Modal
  const handleSubscribeClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Header Section */}
      <Grid
        container
        item
        xs={12}
        sx={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center the logo
          paddingY: { xs: 1, sm: 2 },
          paddingX: { xs: 1, sm: 2 },
          backgroundColor: 'transparent',
          position: 'relative', // For absolute positioning of child elements
          borderBottom: isMdUp ? '2px solid #0066FF' : '1px solid #0066FF',
        }}
      >
        {/* Central Logo */}
        <a href="https://facticity.ai" style={{ textDecoration: 'none' }}>
          <img
            src="/facticityailogo-02.png"
            alt="Facticity.AI"
            style={{
              paddingTop: isMdUp ? '2px' : '12px',
              width: 'auto',
              height: isMdUp ? '32px' : '26px',
            }}
          />
        </a>

        {/* Left IconButton (Sidebar Toggle) */}
        {/* {isAuthenticated && (
          <IconButton
            onClick={toggleSidebar}
            sx={{ position: 'absolute', left: 15, zIndex: 1000 }}
          >
            <MenuIcon sx={{ color: '#0066FF' }} />
          </IconButton>
        )} */}

        {/* Sidebar Component */}
        {/* {isAuthenticated && (
          <Sidebar
            isOpen={isSidebarOpen}
            handleCloseSidebar={handleCloseSidebar}
            sx={{ marginTop: '60px' }}
            setIsSearchMoved={setIsSearchMoved}
            createConversation={createConversation}
          />
        )} */}

        {/* Right Side: ThirdColumn or Create Conversation Button */}
        {(!isAuthenticated || isMdUp) ? (
          <Box sx={{ position: 'absolute', right: 15 }}>
            <ThirdColumn />
          </Box>
        ) : (
          <div>
            
          </div>
        )}
      </Grid>
      {/* Subscription Section */}
      <div className="subscription-container">
      <Box mb={2}>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => navigate('/')}
          align = 'left'
        >
          Back
        </Button>
      </Box>
        <h1 className="page-title">Choose Your Facticity.AI Plan</h1>

        <div className="plans-wrapper">
          {/* Free Tier */}
          <div className="plan-card free-tier">
            <h2 className="plan-title">{freeTier.title}</h2>
            <p className="plan-price">{freeTier.price}</p>
            {renderFeatures(freeTier.features)}
            <button className="plan-button free-button">
              Current Plan
            </button>
          </div>

          {/* Pro Tier */}
          <div className="plan-card pro-tier">
            <h2 className="plan-title">{proTier.title}</h2>
            <p className="plan-price">{proTier.price}</p>
            {renderFeatures(proTier.features)}
            <button
              className="plan-button pro-button"
              onClick={handleSubscribeClick}
            >
              Coming Soon
            </button>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3 className="modal-title">Coming Soon</h3>
              <p className="modal-text">
                Our payment gateway is currently being integrated and will be available soon.
              </p>
              <p className="modal-subtext">
                Stay tuned! In the meantime, enjoy your Pro Subscription Free Trial.
              </p>
              <button
                className="modal-close-button"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Subscription;
