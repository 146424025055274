import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from './AppProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Auth0Provider
          domain="dev-5h33aun.us.auth0.com"
          clientId="fvsQ9DT16eWAbmF6IL6ZApvkpdgjtf3L"
          authorizationParams={{
            redirect_uri: window.location.origin
          }}
          // useRefreshTokens={ false }
          cacheLocation="localstorage"
        >
          <AppProvider>
            <App />
          </AppProvider>
        </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

