// Sidebar.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Typography,
  IconButton, // Import IconButton
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Chat as ChatIcon,
  Explore as ExploreIcon,
  Api as ApiIcon,
  Description as DescriptionIcon,
  Close as CloseIcon, // Import CloseIcon
} from '@mui/icons-material';
import Subscription from '@mui/icons-material/AddCard';
import { Link } from 'react-router-dom';
import { useAppContext } from '../AppProvider';
import ConversationItem from './conversationComponent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';


const Sidebar = ({
  isOpen,
  handleCloseSidebar,
  menuFontSize = '16px',
  conversationFontSize = '10px',
  createConversation,
  isMdUp
}) => {
  const sidebarWidth = 250; // You can adjust this value as needed

  const {
    version,
    setVersion,
    currentConversation,
    conversations,
    setConversations,
    setCurrentConversation,
    queries,
    setQueries,
    ids,
    setIds,
    idHistory,
    setIdHistory,
    setMode,
    setLink,
    errorDisplay,
    setErrorDisplay,
    setIsSearchMoved,
    setNewSearch,
  } = useAppContext();

  const handleConversationSelect = (conversation) => {
    setErrorDisplay("");
    setNewSearch(false);
    if (currentConversation !== conversation.id) {
      setQueries([]); // Reset queries or any other state related to conversations if needed
      setIds([]);
      setIdHistory({});
      setCurrentConversation(conversation.id);
    }
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleCloseSidebar}
      variant="persistent" // Changed to 'persistent' to keep background interactive
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        // Optional: Customize backdrop if needed
        // BackdropProps: {
        //   style: { backgroundColor: 'transparent', pointerEvents: 'none' },
        // },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
          p: 2,
        },
        // Optional: Ensure no backdrop is displayed
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        role="presentation"
        // Removed onClick and onKeyDown to prevent unintended closing
      >
        {/* Logo or Branding with Hide Button */}
        <Box
          sx={{
            // mb: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left', // Center the content horizontally
            textAlign: 'center', // Ensure the text is centered
          }}
        >
          {/* <Typography variant="h5" color="#0066FF">
            Facticity.AI
          </Typography> */}
            <a href="https://facticity.ai" style={{ textDecoration: 'none' }}>
              <img
                  src="/facticityailogo-03.png"
                  alt="Facticity.AI"
                  style={{
                  paddingTop: '4px',
                  paddingLeft: isMdUp ? '13px' : '10px',
                  width: isMdUp ? '28px' : '28px', // Adjust the width as needed
                  height: 'auto', // Maintains aspect ratio
                  }}
              />
            </a>
          {/* Hide Button */}
          <IconButton
            onClick={handleCloseSidebar}
            aria-label="Close sidebar"
            size="large"
            sx={{
              position: 'absolute', // Position the button separately
              right: 0, // Align the button to the right
            }}
          >
            <SpaceDashboardOutlinedIcon />
          </IconButton>
        </Box>


        {/* <Divider /> */}

        {/* Menu Items */}
        <List>
          {/* Discover Button with Tooltip */}
          <Tooltip title="Discover coming soon!" placement="right" arrow>
            <ListItemButton
              sx={{
                fontSize: menuFontSize,
                margin: '0px 0',
                color: 'grey.500',
              }}
              disabled
            >
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary="Discover" />
            </ListItemButton>
          </Tooltip>

          {/* Word Add-in Button with Tooltip */}
          <Tooltip title="Add-in coming soon!" placement="right" arrow>
            <ListItemButton
              sx={{
                fontSize: menuFontSize,
                margin: '8px 0',
                color: 'grey.500',
              }}
              disabled
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Word Add-in" />
            </ListItemButton>
          </Tooltip>

          {/* API Button */}
          <ListItemButton
            component={Link}
            to="/api"
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.500',
            }}
          >
            <ListItemIcon>
              <ApiIcon />
            </ListItemIcon>
            <ListItemText primary="API" />
          </ListItemButton>

          {/* Payments Button */}
          <ListItemButton
            component={Link}
            to="/subscription"
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.500',
            }}
          >
            <ListItemIcon>
              <Subscription />
            </ListItemIcon>
            <ListItemText primary="Subscription" />
          </ListItemButton>

          {/* New Chat Button */}
          <ListItemButton
            onClick={(e) => {
              e.stopPropagation(); // Prevent Drawer from closing
              createConversation();
            }}
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.500',
            }}
          >
            <ListItemIcon>
              <EditNoteIcon />
            </ListItemIcon>
            <ListItemText primary="New Chat" />
          </ListItemButton>
        </List>

        <Divider />

        {/* Conversations List */}
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            mt: 2,
          }}
        >
          <List>
            {conversations
              .slice()
              .reverse()
              .map((conversation) => (
                <ConversationItem
                  key={conversation.id}
                  conversation={conversation}
                  currentConversation={currentConversation}
                  handleConversationSelect={handleConversationSelect}
                  conversationItemStyle={{
                    fontSize: conversationFontSize,
                    paddingLeft: '24px',
                    wordBreak: 'break-all',
                    overflowWrap: 'break-word',
                    margin: '4px 0',
                    padding: '6px 12px',
                    borderRadius: '8px',
                    '&.Mui-selected': {
                      backgroundColor: '#F1F3FE',
                      '&:hover': {
                        backgroundColor: '#F1F3FE',
                      },
                    },
                  }}
                />
              ))}
          </List>
        </Box>

        <Divider />

        {/* Settings Section */}
        <List>
          <ListItemButton
            component={Link}
            to="/settings"
            sx={{
              fontSize: menuFontSize,
              margin: '8px 0',
              color: 'grey.500',
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseSidebar: PropTypes.func.isRequired, // Corrected prop name
  menuFontSize: PropTypes.string,
  conversationFontSize: PropTypes.string,
  createConversation: PropTypes.func.isRequired, // Added missing prop type
};

export default Sidebar;
