import React from 'react';


function extractVideoId(videoLink) {
  let videoId = '';
  if (videoLink.includes('youtube.com/shorts/')) {
      videoId = videoLink.split('youtube.com/shorts/')[1].split('?')[0];
  } else if (videoLink.includes('v=')) {
      videoId = videoLink.split('v=')[1].split('&')[0];
  } else if (videoLink.includes('youtu.be/')) {
      videoId = videoLink.split('youtu.be/')[1].split('?')[0];
  }
  return videoId;
}

function YouTubeEmbed({ videoLink, height }) {
  // Extract the YouTube video ID from the link
  const videoId = extractVideoId(videoLink)

  return (
    <div style={{ height: height }}>
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube Video"
      ></iframe>
    </div>
  );
}

export default YouTubeEmbed;
