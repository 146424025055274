import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Create a Context for the Disambiguation
const AppContext = createContext();


// Create a Provider component
export const AppProvider = ({ children }) => {
  const { user, isAuthenticated }  = useAuth0();

  const [skipDisambiguation, setSkipDisambiguation] = useState(() => {
    try {
      const storedValue = localStorage.getItem('skipDisambiguation');
      return storedValue !== null ? JSON.parse(storedValue) : false;
    } catch (error) {
      console.error('Error reading skipDisambiguation from localStorage:', error);
      return false;
    }
  });

  const [version, setVersion] = useState("pro")
  const [isSearchMoved, setIsSearchMoved] = useState(false);
  const [mode, setMode] = useState("verify")
  const [errorDisplay, setErrorDisplay] = useState("")
  const [email, setEmail] = useState(null);

  const [overlayLogin, setOverlayLogin] = useState(false)

  
  const [ids, setIds] = useState([])
  const [currentConversation, setCurrentConversation] = useState("")
  const [queries, setQueries] = useState([])
  const [conversations, setConversations] = useState([])
  const [link, setLink] = useState("")
  const [NewSearch, setNewSearch] = useState(true)
  const [vismode, setVismode] = useState({})

  const [workspaceLoading, setWorkspaceLoading] = useState(false)
  const [hideSearchBar, setHideSearchBar] = useState(false)
  const [idHistory, setIdHistory] = useState({})
  


  useEffect(() => {
      localStorage.setItem('skipDisambiguation', JSON.stringify(skipDisambiguation));
  }, [skipDisambiguation]);

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem('skipDisambiguation');
      if (storedValue !== null) {
        setSkipDisambiguation(JSON.parse(storedValue));
      }
    } catch (error) {
      // console.error('Failed to retrieve skipDisambiguation from localStorage:', error);
      // Optionally, you can reset to default if parsing fails
      setSkipDisambiguation(false);
    }
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (isAuthenticated) {
      setEmail(user?.email || "");
      setOverlayLogin(false);
    } else {
      setEmail("");
    }
  }, [isAuthenticated, user]);


  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch('https://backend-word-testing-934923488639.us-central1.run.app/update_ids', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email, // replace with actual email
            id: "" // empty id
          })
        });

        if (!response.ok) {
          // console.log("Network response was not ok");
        } else{
            const data = await response.json();
            setConversations(data.ids);
        }


        // setCurrentConversation(data.ids[0]); // Set the first conversation as the current one
      } catch (error) {
        // console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, [email]);

  const fetchTaskStatus = async (conversationId) => {
    // console.log("FETCHING", conversationId)
    let data = null
    try {
      setWorkspaceLoading(true);
      setIsSearchMoved(true);
  
      const response = await fetch('https://backend-word-testing-934923488639.us-central1.run.app/check-multiple-task-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: conversationId }), // assuming the conversationId matches the "_id" expected by the endpoint
      });
    
      // console.log({fetchStatus:response})
      if (!response.ok) {
        setErrorDisplay("Session ended abruptly, no conversation found.")
        console.warn(`Backend responded with status: ${response.status}`);
        return; // Exit early if the response is not OK
      }
  
      data = await response.json();
      // console.log({ data });
  
      if (!data.responses || data.responses.length === 0) {
        // console.log("No responses found");
        return; // Exit early if there are no responses
      }
      // setVismode(data.vis_mode)
      const mappedResponses = data.responses.reduce((acc, response) => {
        acc[response.task_id] = response;
        return acc;
      }, {});
  
      setIdHistory(mappedResponses);
      setIds(Object.keys(mappedResponses));
      setQueries(Object.values(mappedResponses).map((response) => response.input));

      setMode(data.vis_mode.mode)
      setLink(data.vis_mode.link)

      window.history.replaceState(null, '', `/c/${conversationId}`);
    } catch (error) {
      console.warn('An error occurred while fetching task status:', error);
      // Log the error and silently handle it without updating state
    } finally {
      setWorkspaceLoading(false);
    }
  };
  

  useEffect(() => {
    // console.log({ NewSearch });
    if (!isAuthenticated && mode =="extractFacts"){
      setHideSearchBar(true)
    }
    // console.log(isAuthenticated,queries.length)
    if (!isAuthenticated && currentConversation){
      setHideSearchBar(true)
    }
    if (currentConversation !== undefined && currentConversation !== "" && !NewSearch) {
      // console.log("excecuted",NewSearch)
      fetchTaskStatus(currentConversation);
    }

  }, [currentConversation, conversations, NewSearch, vismode, mode]);
  

  // Function to toggle skipDisambiguation state
  const toggleSkipDisambiguation = () => {
    setSkipDisambiguation(prev => !prev);
  };

  return (
    <AppContext.Provider value={{ 
        skipDisambiguation, 
        setSkipDisambiguation, 
        toggleSkipDisambiguation,
        version,
        setVersion,
        currentConversation,
        setCurrentConversation,
        queries,
        setQueries,
        ids,
        setIds,
        conversations,
        setConversations,
        idHistory, 
        setIdHistory,
        isSearchMoved, 
        setIsSearchMoved,
        workspaceLoading,
        mode,
        setMode,
        link, setLink, errorDisplay, setErrorDisplay, email, setNewSearch,
        overlayLogin, setOverlayLogin, hideSearchBar, setHideSearchBar
        }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useAppContext = () => {
  return useContext(AppContext);
};
