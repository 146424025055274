import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { useAppContext } from "../AppProvider";

// Custom hook to parse query parameters
function useQuery() {
  return new URLSearchParams(window.location.search);
}

// Enhanced Divider with centered text
const DividerWithText = ({ text }) => (
  <Box display="flex" alignItems="center" width="100%" my={2}>
    <Divider sx={{ flexGrow: 1 }} />
    <Typography
      variant="body2"
      sx={{ mx: 2, whiteSpace: "nowrap", color: "#5D5D5D" }}
    >
      {text}
    </Typography>
    <Divider sx={{ flexGrow: 1 }} />
  </Box>
);

const LoginModal = ({ notify }) => {
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();

  const { overlayLogin } = useAppContext();

  useEffect(() => {
    const error = query.get("error");
    const errorDescription = query.get("error_description");

    if (error === "access_denied") {
      notify(decodeURIComponent(errorDescription));
      // console.log(decodeURIComponent(errorDescription));
    }
  }, [query, notify]);

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Dialog
      open={overlayLogin}
      // Disable closing the modal by clicking outside or pressing escape
      disableEscapeKeyDown
      // Prevent closing on backdrop click by not handling onClose
      onClose={() => {}}
      PaperProps={{
        sx: {
          minHeight: { xs: "80vh", sm: "600px" },
          minWidth: { xs: "90vw", sm: "400px" },
          borderRadius: 4,
          // Remove boxShadow
          boxShadow: "none",
          // Optional: Add a border or other styles if needed
          border: "1px solid #e0e0e0",
        },
      }}
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          sx={{ minHeight: { xs: "80vh", sm: "600px" }, p: 3 }}
        >
          {/* Logo and Title Section */}
          <Box
            sx={{
              mb: 4,
              textAlign: "center",
              backgroundColor: "#FFFFFF",
              padding: 3,
              borderRadius: 2,
              // Remove boxShadow
              boxShadow: "none",
              width: "100%",
            }}
          >
              <a href="https://facticity.ai" style={{ textDecoration: 'none' }}>
                  <img
                  src="/facticityailogo-02.png"
                  alt="Facticity.AI"
                  style={{
                      paddingTop: '12px',
                      width: 'auto',
                      height: '40px',
                  }}
                  />
              </a>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#5D5D5D",
                fontSize: { xs: "18px", sm: "20px" },
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Recognized by TIME's Best Inventions of 2024
            </Typography>
          </Box>

          {/* Sign-In Section */}
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              padding: 3,
              borderRadius: 2,
              // Remove boxShadow
              boxShadow: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 500, mb: 1 }}>
              Sign In &
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, color: "#5D5D5D", mb: 3 }}
            >
              Let's Get Fact-Checking
            </Typography>
            <Button
                onClick={handleLogin}
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#0066FF",
                  color: "#FFFFFF",
                  padding: 1.5,
                  fontSize: "16px",
                  fontWeight: 600,
                  textTransform: "none", // Ensures the text remains lowercase
                  borderRadius: "30px", // Adds rounded edges
                  "&:hover": {
                    backgroundColor: "#46564d", // Slightly different shade for hover
                  },
                  mb: 2,
                }}
              >
                Continue with email
              </Button>

          </Box>

          {/* Divider with Text */}
          <DividerWithText text="Please sign up for daily pro features." />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
