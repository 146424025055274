import React, { useState } from 'react';
import { ListItem, ListItemText, Collapse, Card, CardContent } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ProfileSection from './ProfileSection';

const AccountSection = ({ open, onToggle, profile, idx }) => {
  const [openProfile, setOpenProfile] = useState(true);

  const handleToggleProfile = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ListItem button onClick={onToggle}>
        <ListItemText primary="Account" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Card variant="outlined" sx={{ mt: 1, mb: 1 }}>
          <CardContent>
            <ListItem button onClick={handleToggleProfile}>
              <ListItemText primary="Profile" />
              {openProfile ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openProfile} timeout="auto" unmountOnExit>
              <ProfileSection profile={profile} idx={idx} />
            </Collapse>
            <ListItem button>
              <ListItemText primary="Subscription" />
            </ListItem>
          </CardContent>
        </Card>
      </Collapse>
    </>
  );
};

export default AccountSection;
