import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Home from './components/Home';
import Settings from './components/Settings/Settings';
import { useAuth0 } from '@auth0/auth0-react';
import Api from './API';
import Subscription from './Subscription';
const App = () => {
  const { user, isAuthenticated }  = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/c/:id" element={<Home />} />
            <Route path="/c/" element={<Home />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/api" element={<Api />} />
            <Route path = "/subscription" element = {<Subscription />} />
            {/* <Route path = "/discover" element = {<Discover />} /> */}
          </Routes>
        </Router>
      </div>
    </ThemeProvider>

  );
};

export default App;
