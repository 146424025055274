import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { IconButton, Grid, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchComponent from './SearchComponent';
import ThirdColumn from './ThirdColumn';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../AppProvider';
import { useParams } from 'react-router-dom';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import LoginModal from './loginModal.js';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Banner from './Banner/Banner.js';


const Home = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  
  const { isAuthenticated } = useAuth0();
  const { isSearchMoved, setIsSearchMoved, setCurrentConversation, setNewSearch, setErrorDisplay, setQueries, setIds, setIdHistory } = useAppContext();
  const { id } = useParams(); // Retrieve the ID from the URL
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [isBannerVisible, setIsBannerVisible] = useState(true)

  useEffect(() => {
    // console.log({ id });
    setCurrentConversation(id);
    setNewSearch(false);
    if (!id) {
      setNewSearch(true);
    }
  }, [id, setCurrentConversation, setNewSearch]);

  useEffect(() => {
    if (isSearchMoved) {
     setIsBannerVisible(false);
    }
  }, [isSearchMoved]);

  const createConversation = () => {
    setIsSearchMoved(false);
    setErrorDisplay("");
    setQueries([]);
    setIds([]);
    setCurrentConversation('');
    setIdHistory({});
    setNewSearch(true);
    window.history.replaceState(null, '', `/`);
    if (!isMdUp) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isMdUp) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [isMdUp]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        background: 'radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, #F1F3FE 60%, #F1F3FE 100%)',
        overflow: 'hidden',
      }}
    >
      <LoginModal />
      <Grid container sx={{ position: 'relative', height: '100%'}}>
        {/* Header */}
        <Grid
        container
        item
        xs={12}
        sx={{
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the logo
            paddingY: { xs: 1, sm: 2 },
            paddingX: { xs: 1, sm: 2 },
            backgroundColor: 'transparent',
            position: 'relative', // For absolute positioning of child elements
            borderBottom: isMdUp ? '2px solid #0066FF' : '1px solid #0066FF'
        }}
        >
        {/* Central Logo */}
        <a href="https://facticity.ai" style={{ textDecoration: 'none' }}>
            <img
            src="/facticityailogo-02.png"
            alt="Facticity.AI"
            style={{
                paddingTop: isMdUp ? '2px': '12px',
                width: 'auto',
                height: isMdUp ? '32px': '26px',
            }}
            />
        </a>

        {/* Left IconButton (Sidebar Toggle) */}
        {isAuthenticated && (
            <IconButton
            onClick={toggleSidebar}
            sx={{ position: 'absolute', left: 15, zIndex: 1000 }}
            >
            <MenuIcon sx={{ color: '#0066FF' }} />
            </IconButton>
        )}

        {/* Sidebar Component */}
        {isAuthenticated && (
            <Sidebar
            isOpen={isSidebarOpen}
            handleCloseSidebar={handleCloseSidebar}
            sx={{ marginTop: '60px' }}
            setIsSearchMoved={setIsSearchMoved}
            createConversation={createConversation}
            />
        )}

        {/* Right Side: ThirdColumn or Create Conversation Button */}
        {(!isAuthenticated || isMdUp) ? (
            <Box sx={{ position: 'absolute', right: 15 }}>
            <ThirdColumn />
            </Box>
        ) : (
            <IconButton
            onClick={createConversation}
            sx={{ position: 'absolute', right: 15, zIndex: 1000 }}
            >
            <AddBoxOutlinedIcon sx={{ color: '#0066FF' }} />
            </IconButton>
        )}
        </Grid>
        <Banner isVisible = {isBannerVisible} setIsVisible = {setIsBannerVisible}/>
        {/* Main Content */}
        <Grid
          container
          item
          xs={12}
          sx={{
            flexGrow: 1,
            height: 'calc(100% - 60px)',
            padding: { xs: 0.5, sm: 0.5 },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              overflowY: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <SearchComponent isSearchMoved={isSearchMoved} setIsSearchMoved={setIsSearchMoved} isMdUp={isMdUp} />
          </Grid>
        </Grid>

        {/* Optional: Additional Columns for Larger Screens */}
        {isMdUp && (
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
            {/* Your additional content for larger screens */}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Home;



